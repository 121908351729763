.headroom--unpinned,
.headroom--pinned,
.headroom {
  .nav-align {
    align-self: center;
  }
  .nav-text {
    display: none;
  }
  .nav-text-2 {
    display: none;
  }
  height: 80px;
  padding-top: 0px;
  background-color: #383838da;
  padding-left: 40px;
  padding-right: 40px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  transition-duration: 200ms !important;
  @media (max-width: 1280px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 1024px) {
    height: 70px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
  }
  @media (max-width: 768px) {
    height: 60px;
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
  }
}

.headroom--top {
  .nav-align {
    align-self: start;
  }
  .nav-text {
    display: flex;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .nav-text-2 {
    display: block;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  height: auto;
  padding-top: 100px;
  background-color: rgba(255, 255, 255, 0);
  padding-left: 100px;
  padding-right: 100px;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  transition-duration: 200ms !important;
  display: grid;
  @media (max-width: 1280px) {
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
    height: 70px;
  }
  @media (max-width: 768px) {
    height: 60px;
    margin-top: 0;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
